import React from "react";
import classNames from "classnames";

export default function PrimaryButton({
	type = "submit",
	className = "",
	processing,
	children,
}) {
	return (
		<button
			type={type}
			className={classNames(
				"inline-flex items-center px-4 py-2 bg-blue-ipbt border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest active:bg-gray-900 transition ease-in-out duration-150",
				{ "opacity-25": processing },
				className,
			)}
			disabled={processing}
		>
			{children}
		</button>
	);
}
