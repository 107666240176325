import { Link } from "@inertiajs/react";
import React from "react";

export default function Guest({ children }) {
	return (
		<div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
			<div>
				<Link href="/">
					<img
						src="/assets/image/logo/Icon-sosmed-IPBT.webp"
						className="w-28 h-28 fill-current text-gray-500"
						alt="ipbtraining"
					/>
				</Link>
			</div>
			<div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
				{children}
			</div>
		</div>
	);
}
